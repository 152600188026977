<template>
  <div class="login-container">
    <v-row justify="center">
      <v-col xs="12" sm="7" md="5" lg="4" xl="3">
        <v-card class="ma-3 mt-8">
          <v-card-title>
            <div class="login-header">
              <img
                style="width: 100%"
                src="../assets/images/logo_tracklift_full.svg"
                alt="Logo de tracklift" />
            </div>
            <v-layout justify-center mb-2>
              Connexion
            </v-layout>
          </v-card-title>
          <v-card-text>
            <!-- Create a login form with vuetify -->
            <v-form @submit.prevent="onSubmit">
              <v-text-field
                outlined
                v-model="form.email"
                label="Email"
                type="email"
                name="email"
                :rules="[v => !!v || 'Email is required']"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="form.password"
                label="Password"
                type="password"
                name="password"
                :rules="[v => !!v || 'Password is required']"
              ></v-text-field>

              <div class="mb-8 d-flex justify-end" style="margin-top: -1.5em;">
                <router-link :to="{ name: 'ResetPasswordRequest' }" style="z-index: 999; position: relative">
                  Mot de passe oublié ?
                </router-link>
              </div>
              <v-layout justify-space-between align-center wrap>
                <v-btn
                  text outlined
                  @click="$router.push({ name: 'Register' })"
                >
                  S'enregistrer
                </v-btn>
                <v-spacer />
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                >
                  Se connecter
                </v-btn>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    console.debug('verified')
    if (this.$store.state.auth.isAuthenticated) {
      if (!this.$store.state.auth.user.isAdmin) {
        this.$router.push({ name: 'Sites' })
        return
      }
      this.$router.push({ name: 'Dashboard' })
    }
  },
  data: () => ({
    form: {
      email: '',
      password: ''
    },
    loading: false
  }),
  methods: {
    onSubmit() {
      this.loading = true
      this.$store.dispatch('auth/login', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'Dashboard' })
        })
        .catch(err => {
          console.error(err)
          this.loading = false
          this.$store.commit('alert/add', {
            text: 'E-mail ou mot de passe incorrect',
            color: 'error'
          })
        })
    }
  }
}
</script>

<style src="@/assets/login.css" />
